<template>
    <OMediaQueryProvider v-slot="{ isMobile }">
        <template v-if="isMobile">
            <ul class="d-flex list-group w-100 h-100">
                <li class="d-flex list-group-item justify-content-between" v-for="item in filtersList" >
                    <button type="button"
                        class="btn btn-link p-0 text-decoration-none text-truncate w-100 text-start"
                        :title="$t(item.Name ?? item.FilterName)"
                        aria-current="true"
                        @click="openOrApply(item);">
                        {{$t(item.FilterName)}}
                    </button>
                    <Delete v-if="item.Mine"  :dataObject="dsFilters" :row="item" class="btn btn-link ms-auto py-0">
                        <i class="bi bi-trash-fill text-danger"></i>
                    </Delete>
                    <Delete v-if="!item.Mine"  :dataObject="dsFilters" :row="item"  :title="$t('Remove shared filter')" softDelete class="btn btn-link ms-auto py-0">
                         <i class="bi bi-x-lg"></i>
                    </Delete>
                </li>
            </ul>
        </template>
        
        <template v-else>
    
            <component :is="dropdown ? 'li' : 'div'" v-for="item in filtersList" >
                <div class="d-flex hover-wrapper" :style="{'width:200px':dropdown}" :class="{'dropdown-item': dropdown, '': !dropdown}" 
                    v-if="showTemplates || !isInputEditor(item)">
                    <button type="button"
                        class="btn btn-link p-0 text-decoration-none text-truncate"
                        :class="[{'text-decoration-line-through':item.Hidden},{'fw-bold':item.PrimKey == filterObject.activeFilter?.PrimKey || item.ID == filterObject.id}]"
                        :title="$t(item.Name ?? item.FilterName)"
                        aria-current="true"
                        @click="openOrApply(item);">
                        <template v-if="item.SharedOrgUnit || item.SharedPerson">
                            <i v-if="item.Mine" class="me-1 bi" :class="item.SharedOrgUnit ? 'bi-share-fill' : 'bi-person-fill'"></i>
                            <i v-else class="me-1 bi" :class="item.SharedOrgUnit ? 'bi-share' : 'bi-person'"></i>
                        </template>
                        {{$t(item.FilterName)}}
                    </button>
                    <button v-if="item.Mine" class="btn btn-link btn-sm ms-2 show-on-hover py-0" @click = "rename(item)" :title="$t('Rename filter')">
                        <i class="bi bi-pencil-fill"></i>
                    </button>
                    <Delete v-if="item.Mine" :dataObject="dsFilters" confirm :row="item" class="btn btn-link btn-sm ms-2 show-on-hover py-0">
                        <i class="bi bi-trash-fill"></i>
                    </Delete>
                      <Delete v-if="!item.Mine && !item.Hidden" :title="$t('Remove shared filter')" confirm :confirmOptions="{message:$t(item.SharedPerson?'This will remove filter from your list':'Filter will be hidden.'),title:$t(item.SharedPerson?'Remove shared filter':'Hide shared filter'),btnTextOk:$t(item.SharedPerson?'Remove':'Hide')}" :row="item" softDelete class="btn btn-link btn-sm ms-2 show-on-hover py-0">
                        <i class="bi bi-x-lg"></i>
                    </Delete>
                    <button class="btn btn-link btn-sm ms-2 show-on-hover py-0" @click = "unhide(item)" v-if="!item.Mine && item.Hidden" :title="$t('Un hide shared filter')">
                         <i class="bi bi-plus-lg"></i>
                    </button>
                </div>
            </component>
           
        </template>
    </OMediaQueryProvider>

    <div class="text-muted" :class="{'px-2':dropdown}" v-if="dsFilters.data.length === 0">{{$t('No filters')}}</div>
</template>

<script setup>
    import  Delete from 'o365.vue.components.Action.Delete.vue';
    import OMediaQueryProvider from 'o365.vue.components.MediaQueryProvider.vue';
    import { defineProps,computed,nextTick,ref, onUnmounted} from "vue";
    import { default as useFieldFilterControls } from 'o365.vue.composables.fieldFilter.ts';
    import context from 'o365.modules.Context.ts';
    components:{}
    const props = defineProps({
        filterObject: null,
        filterComponenent: null,
        dropdown: null,
        showTemplates:{
             type:Boolean,
            default:false
        },
        sharedOnly:{
            type:Boolean,  
            default:false
        },
        includeHidden:{
            type:Boolean,
            default:false
        }       
        
    });
    
    const { getFiltersList } = useFieldFilterControls(null, props.filterObject);
    const dsFilters = props.filterObject.filtersListDO;
    const vContext = ref(context);

    const cancelEvent = context.on('Change',(args)=>{
        vContext.value = args;
    });

    const openOrApply = (item) => {
        props.filterObject.applySavedFilter(item);
        if (props.dropdown) props.dropdown.close();
    }

    const isInputEditor = (item) => {
        return item.FilterCriteria.indexOf("{{") > -1 && item.FilterCriteria.indexOf("}}") > -1;
    }
    
    nextTick(() => {
        if(!dsFilters.state._isLoaded) {
            dsFilters.load();
        }
    })
    console.log("List load");

    const unhide = (item)=>{
        item.Hidden = false;
        props.filterObject.unhideSharedFilter(item);
    }

    async function rename(item) {
        const { default: confirm } = await import('o365.controls.confirm.ts');
        try {
            const result = await confirm({
                message: 'Name',
                title: 'Rename filter',
                btnTextOk: 'Save',
                btnTextCancel: 'Cancel',
                initialTextInputValue: item.FilterName,
                textInputRequired: true
            });
            const name = result.TextInput;
            if (!name) { 
                throw new Error(`Filter name can't be empty`);
            }
            item.FilterName = name;
            await item.save(); 
        } catch (ex) {
            if (!ex.Canceled) {
                const {default: alert} = await import('o365.controls.alert.ts');
                alert(ex.message);
            }
        }
    }

    const filtersList = computed(() => {
        if(!props.sharedOnly) return  getFiltersList(vContext.value).filter(x=>x.Mine);
        if(props.includeHidden) return  getFiltersList(vContext.value).filter(x=>!x.Mine);
        return getFiltersList(vContext.value).filter(x=>!x.Mine&&!x.Hidden);
    });

    onUnmounted(()=>{
        cancelEvent();
    })

</script>
